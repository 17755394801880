import React from "react";
import { Link } from "react-router-dom";

import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faInstagram,
  faLinkedin,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const MenuBar = () => {
  return (
    <footer>
      <div className="footer">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/Pricing">Pricing</Link>
          </li>
          {/**     <li><Link to="/PrivacyPolicy">Privacy Policy</Link></li>  */}
          <li>
            <Link to="/TermsAndConditions">Terms &amp; Conditions</Link>
          </li>
          <li>
            <Link to="/ContactUs">Contact Us</Link>
          </li>
          {/**  <li><Link to="/AboutUs">About Us</Link></li> */}
        </ul>
        <br />
        <p>&copy; MenuLinq 2023. All rights reserved.</p>

        <ul className="social">
          {/**   <li>
                    
                        <a href="https://www.facebook.com"><FontAwesomeIcon icon={faFacebook} /></a>
                    </li> */}
          <li>
            <a
              href="https://www.twitter.com/menulinq"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} size="lg" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/menulinq"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/menulinq/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} size="lg" />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/people/MenuLinq/100088184777603/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} size="lg" />
            </a>
          </li>
          <li>
            <a href="mailto:info@menulinq.com">
              <FontAwesomeIcon icon={faEnvelope} size="lg" />
            </a>
          </li>
          {/**    <li>
                        <a href="https://www.youtube.com"><FontAwesomeIcon icon={faFacebook} /></a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com"><FontAwesomeIcon icon={faFacebook} /></a>
                    </li> */}
        </ul>
      </div>
    </footer>
  );
};

export default MenuBar;

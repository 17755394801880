import React from 'react'
import { Link } from "react-router-dom"
import './Header.css'

import hero_logo from '../../images/hero_logo.png'
import creditImage from '../../images/credit.png'

const Header = () => {
    return (
        <React.Fragment>
            <header className="bg-image">
                <div className="bg-container">
                     <img src={hero_logo} alt="Logo"/> 
                     <br/>
                    <br/>
                    <h2>A contactless digital menu management system that gives you the control to create & manage your digital menus with the ability to auto-generate QR codes for your tables.</h2> 
                    <br/>
                    <br/>
                    {/*  
                    <a href="http://kardiak.co.za" target="_blank" rel="noreferrer"><img className='credit-image' src={creditImage} alt="Credit"/></a>
                    <a href="https://apps.apple.com/za/app/plasees/id1598749583" target="_blank"><img className='badge-image' src={appStoreBadge} alt="App Store Badge"/></a>
                  <h1>Awesome Title</h1>
                    <h2>Placeholder for home page header</h2>   
                    <Link to="/">Download</Link> */}
                </div>
            </header>
        </React.Fragment>
    )
}

export default Header;
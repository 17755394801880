import React from "react";
import "./MenuBar.css";
import menulinq_logo_full_logo from "../../images/menulinq_logo_full_logo.png";

const MenuBar = () => {
  return (
    <nav className="header">
      <div className="nav-wrapper">
        <a className="logo" href="/">
          <img src={menulinq_logo_full_logo} alt="PlaSees Logo" />{" "}
        </a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon"></span>
        </label>

        <ul className="menu">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/Pricing">Pricing</a>
          </li>
          <li>
            <a href="/TermsAndConditions">Terms &amp; Conditions</a>
          </li>
          {/** <li><a href="/PrivacyPolicy">Privacy Policy</a></li> */}
          <li>
            <a href="/ContactUs">Contact Us</a>
          </li>
          {/**   <li><a href="/AboutUs">About Us</a></li> */}
        </ul>
      </div>
    </nav>
  );
};

export default MenuBar;

import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import React from "react";
import Constants from "../../config/Constants";
import "./Pricing.css";

const Pricing = () => {
  const app = initializeApp(Constants.FIREBASE_CONFIG);
  const analytics = getAnalytics(app);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function goToSignUp() {
    logEvent(analytics, "button_click", {
      event: "go_to_sign_up",
      page: window.location.pathname,
    });
    openInNewTab("https://eywa.menulinq.com");
  }

  return (
    <React.Fragment>
      <section className="pricing-content-container">
        <div className="intro-container">
          <h1>Pricing</h1>
          <p>
            Choose the plan that is right for you.
            <br />
            Register now and get the first month free. No credit card required.
          </p>
        </div>

        {/** <div className="columns">
                <ul className="price">
                    <li className="col-header" style={{backgroundColor:'#37387a'}}>Free </li>
                    <li className="grey">$ 24.99 / year</li>
                    <li>Unlimited Consultation</li>
                    <li>10 - 25 Users</li>
                    <li>7 days Audit logs</li>
                    <li>3 days SLA support</li>
                    <li className="grey"><button className="pricing-button">Sign Up</button></li>
                </ul>
                </div> . */}

        <div className="columns">
          <ul className="price">
            <li className="col-header">Entry</li>
            <li className="grey">R50 / month</li>
            <li>1 menu</li>
            <li>Max of 10 menu items per menu</li>
            <li>Menu will contain ads</li>
            <li className="grey">
              <button className="pricing-button" onClick={goToSignUp}>
                Sign Up
              </button>
            </li>
          </ul>
        </div>

        <div className="columns">
          <ul className="price">
            <li className="col-header">Basic</li>
            <li className="grey">R300 / month</li>
            <li>2 menus</li>
            <li>Max of 30 menu items per menu</li>
            <li>Menu will contain ads</li>
            <li className="grey">
              <button className="pricing-button" onClick={goToSignUp}>
                Sign Up
              </button>
            </li>
          </ul>
        </div>

        <div className="columns">
          <ul className="price">
            <li className="col-header">Standard</li>
            <li className="grey">R500 / month</li>
            <li>5 menus</li>
            <li>Max of 60 menu items per menu</li>
            <li>Menu will contain ads</li>
            <li className="grey">
              <button className="pricing-button" onClick={goToSignUp}>
                Sign Up
              </button>
            </li>
          </ul>
        </div>

        <div className="columns">
          <ul className="price">
            <li className="col-header">Premium</li>
            <li className="grey">R900 / month</li>
            <li>Unlimited menus</li>
            <li>Unlimited menu items on each menu</li>
            <li>No ads on menu</li>
            <li className="grey">
              <button className="pricing-button" onClick={goToSignUp}>
                Sign Up
              </button>
            </li>
          </ul>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Pricing;

import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'

import "./ContactUs.css"
import Constants from '../../config/Constants'
import ConsoleLog from '../../utils/ConsoleLog'

const ContactUs = () => {
    const nameInput = React.useRef();
    const emailInput = React.useRef();
    const messageInput = React.useRef();

    const [formStatus, setFormStatus] = React.useState('Send')
    const onSubmit = (e) => {
      e.preventDefault()
      setFormStatus('Submitting...')
      const { name, email, message } = e.target.elements
      let formData = {
        name: name.value,
        email: email.value,
        message: message.value,
      }
      console.log(formData)
      fetch(`${Constants.BACKEND_URL}contactus`, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        }).then(
            (response) => (response.json())
            )
            .then((response)=> { 
                ConsoleLog(response);
                if (response.hasOwnProperty('code') && response.hasOwnProperty('message')) {
                    alert(`Message sending failed: ${response.message}`);
                    setFormStatus('Send');
                }
                else {
                    alert("Message Sent.");
                    setFormStatus('Send');
                    resetForm();
                }
        })
    }

    const resetForm = () => {
        nameInput.current.value = ""
        emailInput.current.value = ""
        messageInput.current.value = ""
    };

    return (
        <React.Fragment>
            <section className="contact-us-content-container">
            {/**    <div className="textArea">
                <h2>Contact Us</h2>
                    <p>
                        We would love to hear from you! Please feel free to connect with us using any of the following social media platform links below.
                    </p>
                </div> */}  

                <div className="container mt-5">
                    <h2 className="mb-3">Contact Us</h2>
                    <p>
                        We would love to hear from you! Please use the form below to get in touch with us.
                    </p>
                    <form onSubmit={onSubmit}>
                        <div className="mb-3">
                        <label className="form-label" htmlFor="name">
                            Name
                        </label>
                        <input className="form-control" type="text" id="name" ref={nameInput} required />
                        </div>
                        <div className="mb-3">
                        <label className="form-label" htmlFor="email">
                            Email
                        </label>
                        <input className="form-control" type="email" id="email" ref={emailInput} required />
                        </div>
                        <div className="mb-3">
                        <label className="form-label" htmlFor="message">
                            Message
                        </label>
                        <textarea className="form-control" id="message"  ref={messageInput} required />
                        </div>
                        <button className="btn btn-danger button" type="submit" style={{backgroundColor: "#1a1a1a", borderColor: "#1a1a1a"}}>
                        {formStatus}
                        </button>
                    </form>
                </div>

             {/**   <div className="block">
                    <div className="row">
                        <div className="col-left">
                            <form id="contact" action="">
                                <h4>Leave us a message</h4>
                                <fieldset>
                                    <input placeholder="Your Name" type="text" tabIndex="1" required autoFocus />
                                </fieldset>
                                <fieldset>
                                    <input placeholder="Subject" type="text" tabIndex="2" required autoFocus />
                                </fieldset>
                                <fieldset>
                                    <input placeholder="Your Email Address" type="email" tabIndex="3" required />
                                </fieldset>
                                <fieldset>
                                    <input placeholder="Your Phone Number" type="tel" tabIndex="4" required />
                                </fieldset>
                                <fieldset>
                                    <textarea placeholder="Type your Message Here...." tabIndex="5" required></textarea>
                                </fieldset>
                                <fieldset>
                                    <button name="submit" type="submit" id="contact-submit" data-submit="...Sending">Submit</button>
                                </fieldset>
                            </form>
                        </div>
                        <div className="col-right">
                            <div id="details">
                                <h4>Drop by our Office</h4>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><i className="fa fa-map-marker fa- " ></i></td>
                                            <td>Your Company name,<br />
                                                G2 Dovedale Parc,<br />
                                                Random Street Name,<br />
                                                Post Code,<br />
                                                State. 
                                                <p></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><i className="fa fa-phone fa- " ></i></td>
                                            <td>Phone No : xx-xxxxxx</td>
                                        </tr>
                                        <tr>
                                            <td><i className="fa fa-clock-o fa- " ></i></td>
                                            <td>
                                                Operation Time:<br />
                                                9.00 am – 5.30 pm (Mon – Sat) <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><i className="fa fa-envelope fa- " ></i></td>
                                            <td>Email : rando@gmail.com</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>  */} 
            </section>
        </React.Fragment>
    )
}

export default ContactUs;
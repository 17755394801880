//import logo from './logo.svg';
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Pricing from "./components/misc/Pricing";
import TermsAndConditions from "./components/misc/TermsAndConditions";
import AboutUs from "./components/pages/AboutUs";
import ContactUs from "./components/pages/ContactUs";
import Home from "./components/pages/Home";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import Constants from "./config/Constants";
import Layout from "./layout/Layout";
import ScrollToTop from "./utils/ScrollToTop";

function App() {
  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(Constants.FIREBASE_CONFIG);
    const analytics = getAnalytics(app);
    logEvent(analytics, "app_initialized");
  }, []);

  return (
    <div className="body-wrap">
      <Router>
        <ScrollToTop />
        <Layout>
          <Routes>
            <Route path={"/AboutUs"} element={<AboutUs />} />
            <Route path={"/ContactUs"} element={<ContactUs />} />
            <Route path={"/PrivacyPolicy"} element={<PrivacyPolicy />} />
            <Route path={"/Pricing"} element={<Pricing />} />{" "}
            <Route
              path={"/TermsAndConditions"}
              element={<TermsAndConditions />}
            />
            <Route path={"/"} element={<Home />} />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;


const Constants = {
    BACKEND_URL : "https://xy1u-afpf-cl4x.n7.xano.io/api:On1F-I7M/",
    DEBUG_MODE : false,

        // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  FIREBASE_CONFIG : {
    apiKey: "AIzaSyBlBDA2sXx-t657jMwWl7MvKT4tWbL3d-M",
    authDomain: "menulinq-website.firebaseapp.com",
    projectId: "menulinq-website",
    storageBucket: "menulinq-website.appspot.com",
    messagingSenderId: "35979461701",
    appId: "1:35979461701:web:c8b666ac9a96048b61cf2c",
    measurementId: "G-2V45YKG0M8"
  },
}

export default Constants;